.header {
  height: var(--header-height);
  padding: 15px;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 100;
  border-bottom: 1px solid var(--clr-border);
  // box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
  }
}

.header__title {
  @media (max-width: 576px) {
    display: none;
  }
}

.header__left {
  display: flex;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 768px) {
    width: 100%; 
    flex-direction: row-reverse;
    justify-content: space-between;
    
  }
}

.header__right {
  display: flex;
  align-items: center;
  column-gap: 15px;


  @media (max-width: 768px) {
    display: none;
    
  }
}

.user__info {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
