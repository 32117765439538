.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  min-height: calc(80dvh - var(--header-height));
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container__sm {
  min-height: calc(35dvh - var(--header-height));
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
