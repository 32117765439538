.wrapper {
  margin-block-start: 4px;
  max-width: 60%;

  &_multiple {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.label {
  display: inline-block;
  margin-block-end: 0.3em;

  font-size: 1rem;

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
}
