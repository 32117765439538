.progress__bar {
  text-align: right;
  margin-block-end: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 374px) {
    font-size: 0.7rem;
    line-height: 1;
  }
}
