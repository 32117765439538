.list {
  display: grid;
  gap: 24px;

  @media (min-width: 577px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 0.5fr));
  }

  @media (max-width: 1024px) {
    gap: 12px;
  }
}

.item {
  position: relative;
  box-shadow: 0 5px 10px 1px rgba(73, 123, 223, 0.1);
  border-radius: 8px;
  border: 1px solid var(--clr-accent);
  background-color: var(--clr-bg);

  @media (max-width: 576px) {
    border-radius: 6px;
  }

  &:hover,
  &:focus-visible {
    border-color: darken(#497bdf, 20);
  }
}

.link {
  padding: 0.8em 2em 0.8em 1em;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  color: var(--clr-accent);
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  transition: box-shadow 0.1s;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.85rem;
  }

  @media (max-width: 374px) {
    padding: 0.6em 1em;
  }

  &:hover,
  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 10px 3px rgba(82, 53, 232, 0.2);
    color: darken(#497bdf, 20);
  }
}

.count {
  position: absolute;
  bottom: 8px;
  right: 12px;

  line-height: 1;
  font-size: 1.4rem;
  font-weight: 600;
  user-select: none;
  color: #e29531;
  pointer-events: none;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }

  @media (max-width: 576px) {
    font-size: 0.8rem;
    bottom: 5px;
    right: 10px;
  }
}
