.wrapper {
  width: 100%;

  background-image: url("../../assets/images/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.window {
  max-width: 1400px;
  margin-inline: auto;
  width: 100%;
  padding: 24px;

  background-color: var(--clr-white);
  border-radius: 16px;
  box-shadow: 0 4px 20px 0 rgba(82, 53, 232, 0.25);
  
  @media (max-width: 576px) {
    padding: 16px 10px;
    border-radius: 8px;
  }

  @media (max-width: 374px) {
    padding: 12px 10px;
  }
}

.title {
  margin-block-end: 0.625em;

  font-weight: 500;
  font-size: 48px;
  text-align: center;
  color: var(--clr-dark-blue);

  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  @media (max-width: 576px) {
    font-size: 1.4rem;
  }

  @media (max-width: 374px) {
    font-size: 1rem;
  }
}

.subtitle {
  margin-block-end: 8px;

  font-size: 24px;
  font-weight: 500;
}
