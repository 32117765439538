.sidebar {
  grid-area: sidebar;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;

  @media (max-width: 1024px) {
    // width: 200px;
  }
  @media (max-width: 768px) {
    left: -400px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    width: 50%;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}

.sidebar__wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 24px 16px 16px;
  border-right: 1px solid var(--clr-border);
  height: 100%;
  background-color: var(--clr-white);
}

.sidebar__header {
  margin-bottom: 30px;
  position: relative;

  @media (max-width: 768px) {
    padding-right: 20px;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;

  @media (min-width: 577px) {
    display: none;
  }
}

.sidebar__name {
  color: var(--clr-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.1;
}

.sidebar__role {
  color: var(--clr-gray);
  font-size: 1rem;
  // line-height: 1.1;
}

.nav {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 14px;

  @media (max-width: 768px) {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--clr-border);
  }
  & > li:hover .nav__link {
    color: var(--clr-dark-blue);
    border-color: #e7e3fc;
    background: var(--clr-bg);

    & .nav__icon {
      color: var(--clr-accent);
    }
  }
}

.nav__link {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 9px 7px;

  text-decoration: none;
  color: var(--clr-gray);
  font-size: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
  transition:
    color 0.3s,
    border-color 0.3s,
    background-color 0.3s;

  &_active {
    color: var(--clr-dark-blue);
    border-color: #e7e3fc;
    background: var(--clr-bg);

    & .nav__icon {
      color: var(--clr-accent);
    }
  }
}

.nav__icon {
  width: 22px;
  height: 22px;

  color: var(--clr-gray);
}

.sidebar__footer {
  margin-top: 100%;

  text-align: center;
  font-size: 12px;
  color: var(--clr-light-gray);
}
