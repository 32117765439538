.table {
  margin-top: 20px;
  width: 700px;
  border: 1px solid var(--clr-border);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;

  @media (max-width: 1024px) {
    font-size: 14px;
    width: 100%;
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }
}

.table__row {
  display: grid;
  grid-template-columns: 40px 1fr 160px 140px;

  border-bottom: 1px solid var(--clr-border);

  &:first-child {
    font-weight: 500;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 576px) {
    grid-template-columns: 30px 1fr 100px 90px;
  }
}

.table__cell {
  text-align: center;
  padding: 8px;
  border-right: 1px solid var(--clr-border);

  &:nth-child(2) {
    text-align: left;
  }

  &:last-child {
    border-right: none;
  }

  @media (max-width: 576px) {
    padding: 6px;
  }
}

.link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.table__empty {
  display: grid;
  place-items: center;
  height: 100px;
  padding: 6px;
  color: var(--clr-gray);
}
