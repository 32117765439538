.title {
  margin-block-end: 0.75em;
  min-height: 2.5em;

  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.1;
  user-select: none;
  white-space: pre-line;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  }

  @media (max-width: 374px) {
    font-size: 0.8rem;
  }
}

.img__wrapper {
  max-width: fit-content;
  overflow: hidden;
  margin-inline: auto;
  margin-block-end: 30px;

  border: 1px solid #9c9cab;
  border-radius: 12px;

  @media (max-width: 574px) {
    border-radius: 6px;
    margin-block-end: 18px;
  }
}

.img {
  max-height: 400px;
  object-fit: cover;

  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;

  @media (max-width: 576px) {
    max-height: 400px;
  }
}
