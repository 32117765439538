:root {
  --clr-black: #000000;
  --clr-white: #ffffff;
  --clr-accent: #5235E8;
  --clr-gray: #717184;
  --clr-light-gray: #A9A9AB;
  --clr-purple-gray: #D6D1FA;
  --clr-dark-blue: #0E0637;
  --clr-bg: #F7F6FE;
  --clr-border: #e3e3e8;
  --clr-red: #FF0000;

  --header-height: 50px;
}

@media (max-width: 768px) {
  :root {
    --header-height: 40px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Regular.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Italic.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--clr-black);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

:where(img, svg, video) {
  display: block;
  max-width: 100%;
}

:where(ul, ol) {
  list-style: none;
  padding: 0;
}

:where(button, input, textarea) {
  font-family: inherit;
}

:where(h1, h2, h3, h4, h5, h6) {
  font: inherit;
}