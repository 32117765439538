.title {
  margin-block-end: 0.9em;

  text-align: center;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width: 576px) {
    margin-block-end: 0.5em;
    font-size: 2.2rem;
  }
}

.inner {
  display: flex;
  justify-content: space-between;

  font-size: 1.4rem;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    row-gap: 12px;
  }

  @media (max-width: 374px) {
    font-size: 1rem;
  }
}

.stats {
  margin-top: 30px;

  @media (max-width: 576px) {
    margin-block-start: 18px;
  }
}

.stats__item {
  color: var(--clr-black);

  &_green {
    color: green;
  }
  &_red {
    color: red;
  }
}

.stats__answers {
  margin-block-start: 15px;
  line-height: 1.2;

  h2 {
    margin-bottom: 5px;
  }
}

.result__title {
  font-size: 2rem;
  font-weight: 500;
  margin-block-end: 0.2em;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 576px) {
    text-align: center;
    font-size: 1.6rem;
  }

  @media (max-width: 374px) {
    font-size: 1.2rem;
  }
}

.result__score {
  margin-bottom: 16px;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 576px) {
    font-size: 1.6rem;
    line-height: 1.1;
  }
}
