.wrapper {
  padding-top: 80px;
  padding-inline: 16px;

  background-image: url("../../assets/images/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.window {
  max-width: 540px;
  margin-inline: auto;
  width: 100%;
  padding: 24px;

  background-color: var(--clr-white);
  border-radius: 16px;
  border: 1px solid var(--clr-black);
}

.title {
  font-weight: 600;
  font-size: 2.3rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 2.2rem;
    line-height: 1.1;
  }
  @media (max-width: 374px) {
    font-size: 1.8rem;
  }
}
