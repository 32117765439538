.radix-themes {
  --default-font-family: 'Rubik';
  --heading-font-family: 'Rubik';

  --cursor-button: pointer;
  --cursor-checkbox: pointer;
  --cursor-disabled: default;
  --cursor-link: pointer;
  --cursor-menu-item: pointer;
  --cursor-radio: pointer;
  --cursor-slider-thumb: grab;
  --cursor-slider-thumb-active: grabbing;
  --cursor-switch: pointer;

  /* Custom color styles */
  /* --teal-1: #091315;
  --teal-2: #101b1d;
  --teal-3: #0b2c32;
  --teal-4: #003942;
  --teal-5: #024650;
  --teal-6: #105560;
  --teal-7: #1b6775;
  --teal-8: #0098ad;
  --teal-9: #0098ad;
  --teal-10: #2c8999;
  --teal-11: #56cce2;
  --teal-12: #b6ebf6;

  --teal-a1: #0078de05;
  --teal-a2: #00d6fd0d;
  --teal-a3: #00d0fb24;
  --teal-a4: #00d2fd35;
  --teal-a5: #00d8fe44;
  --teal-a6: #0edeff55;
  --teal-a7: #29dcfd6c;
  --teal-a8: #2de0fe89;
  --teal-a9: #00defea8;
  --teal-a10: #41e3ff92;
  --teal-a11: #60e6ffe0;
  --teal-a12: #bcf3fef6;

  --teal-contrast: #fff;
  --teal-surface: #10252980;
  --teal-indicator: #0098ad;
  --teal-track: #0098ad;

  --blue-1: #07121a;
  --blue-2: #0d1b24;
  --blue-3: #082a3d;
  --blue-4: #063751;
  --blue-5: #0b4564;
  --blue-6: #155477;
  --blue-7: #1e668f;
  --blue-8: #237cad;
  --blue-9: #114968;
  --blue-10: #1e668f;
  --blue-11: #7dc4f3;
  --blue-12: #cdeeff;

  --blue-a1: #002bf70a;
  --blue-a2: #008bf815;
  --blue-a3: #0096fb30;
  --blue-a4: #009efe45;
  --blue-a5: #00a6ff59;
  --blue-a6: #1aacfd6e;
  --blue-a7: #2ab2ff87;
  --blue-a8: #114968;
  --blue-a9: #89d5ff;
  --blue-a10: #86cffffa;
  --blue-a11: #82cdfef3;
  --blue-a12: #cdeeff;

  --blue-contrast: #fff;
  --blue-surface: #0a253780;
  --blue-indicator: #89d5ff;
  --blue-track: #89d5ff; */
}