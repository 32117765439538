.container {
  font-size: 0.875rem;
  color: var(--gray-12);
}

/* This need to overide default react-select plugin styles */
.container .control {
  min-height: 0;
  max-height: 32px;
  align-content: center;

  background-color: var(--color-surface);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  color: var(--gray-12);
}

.container .control.error {
  border-color: red;
}

.container .placeholder {
  color: var(--gray-a10);
}

.container .menu {
  min-width: max-content;
  /* max-width: 350px; */
  border-radius: 10px;
}

.container .menu__list {
  padding: 6px;
}

.container .option {
  padding: 6px 10px 6px 24px;
  position: relative;

  cursor: pointer;
  border-radius: 6px;
}

.container .focused {
  color: var(--clr-white);
  background-color: var(--clr-accent);
}

.container .option.selected {
  background-color: var(--clr-white);
  color: var(--gray-12);
}

.container .option.selected::before {
  content: '✓';
  opacity: 1;

  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.container .option:hover {
  color: var(--clr-white);
  background-color: var(--clr-accent);
}

.container .indicator {
  padding: 8px;
}

.container .indicator svg {
  width: 14px;
  height: 14px;
  color: var(--gray-12);
  opacity: 0.7;
}

.container .clear__indicator svg {
  width: 16px;
  height: 16px;
  color: var(--gray-12);
  opacity: 0.7;
}