.card {
  // max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--clr-purple-gray);
  background-color: var(--clr-bg);
  transition: all 0.15s;
  overflow: hidden;

  &:hover,
  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 10px 1px rgba(82, 53, 232, 0.2);
    border-color: var(--clr-accent);
    color: darken(#497bdf, 20);
    cursor: pointer;
  }
}

.card__text {
  margin-bottom: 2em;

  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
  line-height: 1.3;

  .card_sm & {
    margin-bottom: 0.5em;
    font-size: 1rem;
  }
}

.card__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__subtitle {
  color: var(--clr-gray);
  font-size: 14px;
}
