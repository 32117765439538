.app {
  grid-area: main;
  background-color: var(--clr-white);
}

.grid {
  height: 100%;
  grid-template-columns: 250px 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  grid-template-areas:
    "sidebar header header"
    "sidebar main main"
    "sidebar main main";

  @media (min-width: 769px) {
    display: grid;
  }
}

.container {
  min-height: calc(100vh - var(--header-height));
  padding: 50px 20px;

  @media (max-width: 576px) {
    padding: 40px 16px 50px;
  }
}

.clear-link {
  text-decoration: none;
  color: inherit;
}

.title {
  margin-bottom: 20px;
  font-size: 36px;
  color: var(--clr-dark-blue);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 576px) {
    font-size: 20px;
  }
}

.subtitle {
  margin-bottom: 12px;
  font-size: 26px;
  color: var(--clr-dark-blue);
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 22px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
  }
}

.card__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
  gap: 18px 24px;

  &_sm {
    grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
    column-gap: 16px;
  }

  &_column {
    grid-template-columns: 0.55fr;
  }
}
