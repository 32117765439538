.list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  &_multiple {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
    column-gap: 24px;
  }

  @media (max-width: 768px) {
    row-gap: 8px;
  }
}
