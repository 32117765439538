.form__control {
  display: flex;
  align-items: center;
  padding: 0.65em 0.8em;

  border-radius: 8px;
  border: 1px solid var(--clr-black);
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1.25;
  user-select: none;
  --clr-active: #cdedfd;

  & > sup {
    font-size: 0.8rem;
    align-self: flex-start;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    border-radius: 6px;
  }

  @media (max-width: 576px) {
    border-radius: 4px;
    font-size: 0.8rem;

    & > sup {
      font-size: 0.65rem;
    }
  }
}

.form__input {
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  &:checked + .form__control {
    background-color: var(--clr-active);
  }
  &_checked + .form__control {
    background-color: var(--clr-active);
  }

  &:focus-visible + .form__control {
    outline: 1px solid var(--clr-black);
  }
}

.text {
  user-select: none;
}
