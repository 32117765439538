.img__wrapper {
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.img {
  width: 100px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 60px;
  }
}
